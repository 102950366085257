@import '../assets/scss/variables.scss';
@import 'normalize.css';
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';
@import 'animate.css/animate.min.css';

.fp-watermark {
  display: none;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

body {
  background-color: #fefcfe;
  box-sizing: border-box;
}
